import * as React from 'react';
import { graphql } from 'gatsby';

import * as styles from './404.module.scss';
import { Meta } from '../components';
import { IPageDetails, IPageContext } from '../interfaces';
import { PageElements } from '../elements';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
  };
  pageContext: IPageContext;
}

const NotFoundPage: React.FunctionComponent<IProps> = (props) => {
  const { data, pageContext } = props;
  const { title, pageElements, seo } = data.contentfulPage;

  return (
    <div className={styles.container}>
      <Meta seo={seo} langKey='en' />
      <h1 className='centered-content'>{title}</h1>
      <PageElements elements={pageElements} pageContext={pageContext} />
    </div>
  );
};

// TODO figure out a way to get the locale here
export const query = graphql`
  query NotFoundQuery {
    contentfulPage(slug: { eq: "404" }, node_locale: { eq: "en-US" }) {
      ...PageDetails
    }
  }
`;

export default NotFoundPage;
